import { type SearchModuleFilters } from '@ev/search-modules-components';
import type { ParseKeys, TFunction } from 'i18next';

export const metaDescriptionsIndex = (
  t: TFunction<'common'>,
  filters: SearchModuleFilters,
  placeName?: string
): Record<string, string> & { defaultValue: string } => {
  const propertyType = filters.propertyType;
  const marketingType = filters.marketingType;

  return {
    city: placeName ? `${placeName}, ` : '',
    propertyType: propertyType
      ? `<span class='ev-headline-search-filter'>${t(
          `search.searchbar.advFilter.propertyType.${propertyType}` as ParseKeys<'common'>
        )}</span>, `
      : '',
    marketingType: marketingType
      ? `<span class='ev-headline-search-filter'>${t(
          `search.searchbar.${marketingType}` as ParseKeys<'common'>
        )}</span>`
      : '',
    defaultValue: '',
  };
};

export const metaDescriptionsExpose = (marketingDescription = ''): string => {
  const descriptionArray = marketingDescription
    .replace(/([.?!])\s*(?=[A-Z])/g, '$1|')
    .split('|');

  return `${descriptionArray[0] ? descriptionArray[0] : ''} ${
    descriptionArray[1] ? descriptionArray[1] : ''
  }`;
};

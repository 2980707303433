import { useEffect } from 'react';

type GTMTrackingHook = (params?: { masterdataShopId?: string }) => void;

function initializeGTMTracking(masterdataShopId?: string) {
  window.evGTMTracking.initPage({
    masterdataShopId: masterdataShopId || '',
  });
}

export const useGTMTracking: GTMTrackingHook = (params) => {
  useEffect(() => {
    const scriptId = 'ev-gtm-script';

    if (document.getElementById(scriptId)) {
      initializeGTMTracking(params?.masterdataShopId);
      return;
    }

    const gtmScriptUrl = process.env['NEXT_PUBLIC_GTM_SCRIPT_URL'];

    if (!gtmScriptUrl) {
      return;
    }

    const script = document.createElement('script');

    script.async = true;
    script.src = gtmScriptUrl;
    script.id = scriptId;
    script.onload = () => {
      initializeGTMTracking(params?.masterdataShopId);
    };
    document.head.appendChild(script);
  }, []);
};

import { type ExposePath } from '@ev/search-modules-api';
import HeadNext from 'next/head';
import { useTranslation } from 'next-i18next';
import React from 'react';

type HeadType = {
  title: string;
  description?: string;
  index: boolean;
  imageUrl?: string;
  paths?: ExposePath[];
  canonicalUrl?: string;
};

export const Head = ({
  title,
  description,
  index,
  imageUrl,
  paths,
  canonicalUrl,
}: HeadType): React.ReactElement => {
  const {
    i18n: { language },
  } = useTranslation();
  const indexAttr = index ? 'index,follow' : 'noindex,nofollow';
  return (
    <HeadNext>
      <title>{title}</title>
      {Boolean(description) && (
        <meta name="description" content={description} />
      )}
      <meta property="og:title" content={title} />
      <meta property="og:locale" content={language} />
      <meta property="og:site_name" content="Engel &amp; Völkers" />
      {Boolean(description) && (
        <meta property="og:description" content={description} />
      )}
      {Boolean(imageUrl) && <meta property="og:image" content={imageUrl} />}
      <meta name="robots" content={indexAttr} />
      {canonicalUrl !== '' ? (
        <link
          rel="canonical"
          href={`https://www.engelvoelkers.com${canonicalUrl}`}
        />
      ) : null}
      {paths?.map((path) => {
        return (
          <link
            key={path.path}
            rel="alternate"
            hrefLang={path.language}
            href={`https://www.engelvoelkers.com${path.path}`}
          />
        );
      })}
    </HeadNext>
  );
};

import { type SelfDescribingJson, trackPageView } from '@ev/snowplow-library';
import { useEffect } from 'react';

export const useTrackPageView = (
  isSnowplowTrackerInit = false,
  contexts: SelfDescribingJson[]
) => {
  useEffect(() => {
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(() => {
      if (isSnowplowTrackerInit) {
        trackPageView({
          context: [...contexts],
        });
      }
    });
  }, [isSnowplowTrackerInit]);
};

import { useEffectOnce } from 'react-use';

const pushGTMVariable = (key: keyof DataLayerEvent, value: unknown): void => {
  window.dataLayer?.push({
    [key]: value,
  });
};
export const useGTMDataLayer = (key: string, value: unknown): void => {
  useEffectOnce(() => {
    pushGTMVariable(key, value);
  });
};

import { useEffect, useMemo } from 'react';
import { useCookie } from 'react-use';

export const useMapFeature = (isMapEnabledProp: boolean) => {
  const [mapCookie, updateMapCookie] = useCookie('ns-map-enabled');

  useEffect(() => {
    if (
      window.location.hash === '#ns-map-disabled' ||
      window.location.host.includes('engelvoelkers.com')
    ) {
      updateMapCookie('false');
    } else if (window.location.hash === '#ns-map-enabled') {
      updateMapCookie('true');
    }
  }, [updateMapCookie]);

  const isMapEnabled = useMemo(() => {
    return mapCookie === 'true' || (mapCookie !== 'false' && isMapEnabledProp);
  }, [mapCookie, isMapEnabledProp]);

  return {
    isMapEnabled,
  };
};
